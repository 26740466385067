
export enum APICommandName {
  USER_GET_INFO = 'user.get_info',

  GET_EVENT_PROFILE = 'user.get_event_profile',
  EDIT_EVENT_PROFILE = 'user.edit_event_profile',

  USER_ADD_CONTACT = 'user.add_contact',
  USER_EDIT_CONTACT = 'user.edit_contact',
  USER_REMOVE_CONTACT = 'user.remove_contact',
  USER_SET_DEFAULT_CONTACT = 'user.set_default_contact',
  USER_REQUEST_CONTACT_VERIFICATION = 'user.request_contact_verification',
  USER_REQUEST_PHONE_VERIFICATION = 'user.request_phone_verification',
  USER_COMPLETE_EMAIL_VERIFICATION = 'user.complete_email_verification',
  USER_COMPLETE_PHONE_VERIFICATION = 'user.complete_phone_verification',

  CONFIRMATION_GET_INFO = 'confirmation.get_info',
  CONFIRMATION_ACCEPT = 'confirmation.accept',
  CONFIRMATION_DECLINE = 'confirmation.decline',

  REGISTRATION_INITIATION = 'registration.initiation',
  REGISTRATION_CONFIRM = 'registration.complete_registration_from_email',

  LOGIN_WITH_IDENTITY_AND_PASSWORD = 'login.with_identity_and_password',

  LOGIN_REQUEST_ONE_TIME_ACCESS_FROM_EMAIL = 'login.request_one_time_access_from_email',
  LOGIN_ONE_TIME_ACCESS_FROM_EMAIL = 'login.one_time_access_from_email',

  ADD_REFERRAL_ADD = 'add.add_referral',

  USER_PROFILE_EDIT = 'user.edit_info',
  USER_PROFILE_CHANGE_PASSWORD = 'user.change_password',

  USER_GET_EVENTS = 'user.get_events',

  USER_PREVIEW_EVENT_FOR_INVITE = 'user.preview_event_for_invite',
  USER_GET_INVITES = 'user.get_invites',
  USER_TOUCH_INVITE = 'user.touch_invite',
  USER_PROGRESS_INVITE = 'user.progress_invite',
  USER_ACCEPT_INVITE = 'user.accept_invite',
  USER_DECLINE_INVITE = 'user.decline_invite',

  DICTIONARY_GET_POSITIONS = 'dictionary.get_positions',
  DICTIONARY_GET_CITIES = 'dictionary.get_cities',
  DICTIONARY_GET_COMPANIES_DICTIONARY = 'dictionary.get_companies',

  COMPANIES_GET_COMPANIES = 'admin.get_companies',

  COMPANY_ADMIN_GET_USERS = 'admin.get_users',
  COMPANY_ADMIN_EDIT_ROLE = 'admin.edit_role',

  COMPANY_GET_INFO = 'company.get_info',
  COMPANY_GET_ORDERS = 'company.get_orders',
  COMPANY_GET_ORDER = 'company.get_order',
  COMPANY_GET_ORDERS_STATISTICS = 'company.get_orders_statistics',
  COMPANY_RESET_ORDER_GENERAL_DISTRIBUTION_TOKEN = 'company.reset_order_general_distribution_token',
  COMPANY_CANCEL_ORDER_DISTRIBUTION = 'company.cancel_order_distribution',
  COMPANY_CREATE_ORDER_PERSONAL_DISTRIBUTION_TOKEN = 'company.create_order_personal_distribution_token',
  CREATE_ORDER_PERSONAL_EMAIL_DISTRIBUTION = 'company.create_order_personal_email_distribution',
  COMPANY_RENEW_ORDER_PERSONAL_DISTRIBUTIONS = 'company.renew_order_personal_distributions',

  LOGOUT = 'logout',

  SMARTREDIRECT_RESOLVE = 'smartredirect.resolve',

  LOGIN_REQUEST_WITH_ONE_TIME_ACCESS_CODE = 'login.request_with_one_time_access_code',

  LOGIN_WITH_ONE_TIME_ACCESS_CODE = 'login.with_one_time_access_code',

  USER_REQUEST_CHANGE_PASSWORD = 'user.request_change_password'
}
