import { Injectable } from '@angular/core';

import { ApiService } from '../../api/api.service';

import { APICommandName } from '../../api/commands/APICommandName';
import { DataLayerErrorCode, UnionDataLayerErrors } from '../../DataLayerErrorCode';
import { APIErrorCode, APIErrorForbiddenCommandExtraCode } from '../../api/APIErrorCode';
import { APIResponseHeaders } from '../../api/APIResponseHeaders';

import { Observable } from 'rxjs';
import { eitherifyAPIServiceResponse } from '../../api/eitherifyAPIServiceResponse';
import {
  bitapEither,
  createCommonErrors,
  Either,
  I18nService,
  Locale,
  mapEither,
  ofLeftEither, tapEither,
} from '@ui/jug-ui';
import { first, map, switchMap, tap } from 'rxjs/operators';

import { UserProfileModel } from '../../../models/user/user-profile.model';
import { CurrentUserDataModel } from '../../../models/user/current-user.data-model';
import { CurrentUserModel } from '../../../models/user/current-user.model';
import { CurrentUserApiModelTransformer } from '../../../models/user/current-user.api-model-transformer';

import { EditingCurrentUserProfileAPIModel, UserProfileContactType } from '../../api/models/user/CurrentUserProfileAPIModel';
import { UserProfileChangePasswordAPIRequest } from '../../api/commands/user/profile/UserProfileChangePasswordAPIRequest';
import { UserProfileChangePasswordAPIResponse } from '../../api/commands/user/profile/UserProfileChangePasswordAPIResponse';
import { UserAddContactAPIResponse } from '../../api/commands/user/contacts/UserAddContactAPIResponse';
import { UserSetDefaultContactAPIResponse } from '../../api/commands/user/contacts/UserSetDefaultContactAPIResponse';
import { UserRemoveContactAPIResponse } from '../../api/commands/user/contacts/UserRemoveContactAPIResponse';
import { UserEditContactAPIResponse } from '../../api/commands/user/contacts/UserEditContactAPIResponse';
import { UserRequestContactVerificationAPIResponse } from '../../api/commands/user/contacts/UserRequestContactVerificationAPIResponse';
import { UserCompleteEmailVerificationAPIResponse } from '../../api/commands/user/contacts/UserCompleteEmailVerificationAPIResponse';
import { ConfirmationGetInfoAPIResponse } from '../../api/commands/user/confirmation-contacts/ConfirmationGetInfoAPIResponse';
import { ConfirmationAcceptAPIResponse } from '../../api/commands/user/confirmation-contacts/ConfirmationAcceptAPIResponse';
import { ConfirmationDeclineAPIResponse } from '../../api/commands/user/confirmation-contacts/ConfirmationDeclineAPIResponse';
import { NavigationService } from '../../../../routing/navigation.service';
import {
  UserCompleteEmailVerificationAPIRequest
} from '../../api/commands/user/contacts/UserCompleteEmailVerificationAPIRequest';


@Injectable()
export class UserProfileService {

  constructor(
    private userProfileModel: UserProfileModel,
    private apiService: ApiService,
    public currentUserModel: CurrentUserModel,
    private currentUserApiModelTransformer: CurrentUserApiModelTransformer,
    private i18nService: I18nService,
  ) { }

  private _tryToSandRequestForPasswordChanging(
    newPassword: string,
    oneTimeTokenToSetNewPassword: string,
    currentPassword?: string,
  ): Observable<Either<UserProfileChangePasswordAPIResponse, UnionDataLayerErrors>> {
    if (oneTimeTokenToSetNewPassword || currentPassword) {
      let commandData: UserProfileChangePasswordAPIRequest;
      if (oneTimeTokenToSetNewPassword) {
        commandData = {
          oneTimeTokenToSetNewPassword: oneTimeTokenToSetNewPassword,
          newPassword: newPassword,
        };
      } else {
        commandData = {
          currentPassword: currentPassword,
          newPassword: newPassword,
        };
      }

      return this.apiService
        .request(
          APICommandName.USER_PROFILE_CHANGE_PASSWORD,
          commandData,
        )
        .pipe(
          eitherifyAPIServiceResponse(),
          tapEither(response => {
            if (response && response.meta && response.meta.redirect) {
              window.location.href = response.meta.redirect;
            }
          }),
        );

    } else {
      return ofLeftEither(
        createCommonErrors(DataLayerErrorCode.USER_PROFILE_CANT_CHANGE_PASSWORD_WITHOUT_PASSWORD_OR_TOKEN)
      );
    }
  }

  changePasswordByCode(
    newPassword: string,
    oneTimeTokenToSetNewPassword: string
  ) {
    const commandData: UserProfileChangePasswordAPIRequest = {
      newPassword,
      oneTimeTokenToSetNewPassword,
    };

    return this.apiService
      .request(
        APICommandName.USER_PROFILE_CHANGE_PASSWORD,
        commandData,
      )
      .pipe(
        eitherifyAPIServiceResponse(),
        bitapEither(
          userProfileChangePasswordAPIResponse => {
            this.currentUserModel.oneTimeTokenToSetNewPassword.set(null);
            const currentUserDataModel = this.currentUserApiModelTransformer.transform(userProfileChangePasswordAPIResponse.user);

            this.currentUserModel.user.set(currentUserDataModel);

            const userProfileModel = this.userProfileModel;
            userProfileModel.changePasswordSettingErrors.set(null);
            userProfileModel.changePasswordDataState.success();
          },
          errors => {
            const userProfileModel = this.userProfileModel;
            userProfileModel.changePasswordSettingErrors.set(errors);
            userProfileModel.changePasswordDataState.fail();
          }
        ),
      );
  }

  tryToChangePassword(
    newPassword: string,
    currentPassword?: string,
  ): Observable<Either<boolean, UnionDataLayerErrors>> {
    return this.currentUserModel.oneTimeTokenToSetNewPassword
      .stream$
      .pipe(
        first(),
        tap(oneTimeTokenToSetNewPassword => {
          if (oneTimeTokenToSetNewPassword || currentPassword) {
            this.userProfileModel.changePasswordDataState.fetch();
          }
        }),
        switchMap(oneTimeTokenToSetNewPassword => {
          return this._tryToSandRequestForPasswordChanging(
            newPassword,
            oneTimeTokenToSetNewPassword,
            currentPassword
          );
        }),
        bitapEither(
          userProfileChangePasswordAPIResponse => {
            this.currentUserModel.oneTimeTokenToSetNewPassword.set(null);
            const currentUserDataModel = this.currentUserApiModelTransformer.transform(userProfileChangePasswordAPIResponse.user);

            this.currentUserModel.user.set(currentUserDataModel);

            const userProfileModel = this.userProfileModel;
            userProfileModel.changePasswordSettingErrors.set(null);
            userProfileModel.changePasswordDataState.success();
          },
          errors => {
            const userProfileModel = this.userProfileModel;
            userProfileModel.changePasswordSettingErrors.set(errors);
            userProfileModel.changePasswordDataState.fail();
          }
        ),
        mapEither(true),
      );
  }

  tryChangeLanguage(
    locale: Locale
  ): Observable<Either<CurrentUserDataModel, UnionDataLayerErrors>> {
    this.i18nService.setLocale(locale);
    return this.currentUserModel.isLoggedIn.stream$
      .pipe(
        first(),
        switchMap(isLoggedIn => {
          if (isLoggedIn) {
            return this.editUserProfile({
              lang: locale.toString().toUpperCase(),
            });
          }
          return ofLeftEither<CurrentUserDataModel, UnionDataLayerErrors>(
            createCommonErrors(
              DataLayerErrorCode.USER_NOT_LOGGED_IN,
            )
          );
        })
      );
  }

  editUserProfile(
    profileFields: EditingCurrentUserProfileAPIModel
  ): Observable<Either<CurrentUserDataModel, UnionDataLayerErrors>> {
    const userProfileModel = this.userProfileModel;
    userProfileModel.profileUpdateDataState.fetch();

    return this.apiService
      .request(
        APICommandName.USER_PROFILE_EDIT,
        {
          user: profileFields,
        }
      )
      .pipe(
        eitherifyAPIServiceResponse(),
        mapEither(apiResponse => this.currentUserApiModelTransformer.transform(apiResponse.user)),
        bitapEither(
          currentUserDataModel => {
            this.currentUserModel.user.set(currentUserDataModel);
            userProfileModel.profileUpdateErrors.set(null);
            userProfileModel.profileUpdateDataState.success();
          },
          errors => {
            userProfileModel.profileUpdateErrors.set(errors);
            userProfileModel.profileUpdateDataState.fail();
          }
        )
      );
  }

  addContact(contactType: UserProfileContactType, contactValue: string):
      Observable<Either<UserAddContactAPIResponse, UnionDataLayerErrors>> {
    const commandData = {
      contactType,
      contactValue,
    };

    return this.apiService
      .request(
        APICommandName.USER_ADD_CONTACT,
        commandData
      )
      .pipe(
        eitherifyAPIServiceResponse()
      );
  }

  setContactAsDefault(contactId: number): Observable<Either<UserSetDefaultContactAPIResponse, UnionDataLayerErrors>> {
    const commandData = {
      contactId
    };

    return this.apiService
      .request(
        APICommandName.USER_SET_DEFAULT_CONTACT,
        commandData
      )
      .pipe(
        eitherifyAPIServiceResponse()
      );
  }

  removeContact(contactId: number): Observable<Either<UserRemoveContactAPIResponse, UnionDataLayerErrors>> {
    const commandData = {
      contactId
    };

    return this.apiService
      .request(
        APICommandName.USER_REMOVE_CONTACT,
        commandData
      )
      .pipe(
        eitherifyAPIServiceResponse()
      );
  }

  editContact(contactId: number, contactValue: string): Observable<Either<UserEditContactAPIResponse, UnionDataLayerErrors>> {
    const commandData = {
      contactId,
      contactValue
    };

    return this.apiService
      .request(
        APICommandName.USER_EDIT_CONTACT,
        commandData
      )
      .pipe(
        eitherifyAPIServiceResponse()
      );
  }

  requestEmailVerification(contactId: number, from?: string): Observable<Either<UserRequestContactVerificationAPIResponse, UnionDataLayerErrors>> {
    const commandData = {
      contactId,
      from
    };

    return this.apiService
      .request(
        APICommandName.USER_REQUEST_CONTACT_VERIFICATION,
        commandData
      )
      .pipe(
        eitherifyAPIServiceResponse()
      );
  }

  requestPhoneVerification(contactId: number, from?: string): Observable<Either<UserRequestContactVerificationAPIResponse, UnionDataLayerErrors>> {
    const commandData = {
      contactId,
      from
    };

    return this.apiService
      .request(
        APICommandName.USER_REQUEST_CONTACT_VERIFICATION,
        commandData
      )
      .pipe(
        eitherifyAPIServiceResponse()
      );
  }

  completeEmailVerification(secretKey: string): Observable<Either<UserCompleteEmailVerificationAPIResponse, UnionDataLayerErrors>> {
    const commandData = {
      secretKey,
      contactVerifiedFrom: 'user_profile'
    } as UserCompleteEmailVerificationAPIRequest;

    return this.apiService
      .request(
        APICommandName.USER_COMPLETE_EMAIL_VERIFICATION,
        commandData
      )
      .pipe(
        map(response => {
          const DUPLICATE_IDENTITY_ERROR = response.errors.find(error => error.code === APIErrorCode.DUPLICATE_IDENTITY);

          if (
            DUPLICATE_IDENTITY_ERROR &&
            response.headers.hasOwnProperty(APIResponseHeaders.CONFIRMATION_REQUESTED)
          ) {
            DUPLICATE_IDENTITY_ERROR.extra = { reason: APIErrorForbiddenCommandExtraCode.DUPLICATE_IDENTITY_AND_CONFIRM_MERGE_LK };
          }

          return response;
        }),
        eitherifyAPIServiceResponse()
      );
  }

  completePhoneVerification(secretKey: string, contactVerifiedFrom?: 'user_profile' | 'user_event_profile'): Observable<Either<UserCompleteEmailVerificationAPIResponse, UnionDataLayerErrors>> {
    const commandData = {
      secretKey,
      contactVerifiedFrom
    };

    return this.apiService
      .request(
        APICommandName.USER_COMPLETE_PHONE_VERIFICATION,
        commandData
      )
      .pipe(
        map(response => {
          const DUPLICATE_IDENTITY_ERROR = response.errors.find(error => error.code === APIErrorCode.DUPLICATE_IDENTITY);

          if (
            DUPLICATE_IDENTITY_ERROR &&
            response.headers.hasOwnProperty(APIResponseHeaders.CONFIRMATION_REQUESTED)
          ) {
            DUPLICATE_IDENTITY_ERROR.extra = { reason: APIErrorForbiddenCommandExtraCode.DUPLICATE_IDENTITY_AND_CONFIRM_MERGE_LK };
          }

          return response;
        }),
        eitherifyAPIServiceResponse()
      );
  }

  confirmationGetInfo(): Observable<Either<ConfirmationGetInfoAPIResponse, UnionDataLayerErrors>> {
    const commandData = {};

    return this.apiService
      .request(
        APICommandName.CONFIRMATION_GET_INFO,
        commandData
      )
      .pipe(
        eitherifyAPIServiceResponse()
      );
  }

  confirmationAccept(): Observable<Either<ConfirmationAcceptAPIResponse, UnionDataLayerErrors>> {
    const commandData = {};

    return this.apiService
      .request(
        APICommandName.CONFIRMATION_ACCEPT,
        commandData
      )
      .pipe(
        eitherifyAPIServiceResponse()
      );
  }

  confirmationDecline(): Observable<Either<ConfirmationDeclineAPIResponse, UnionDataLayerErrors>> {
    const commandData = {};

    return this.apiService
      .request(
        APICommandName.CONFIRMATION_DECLINE,
        commandData
      )
      .pipe(
        eitherifyAPIServiceResponse()
      );
  }
}
