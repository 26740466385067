import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LoggedInGuard } from './ui/pages/main/logged-in-guard.service';
import { AuthService } from './data/services/auth/auth.service';
import { AuthPartGuard } from './ui/pages/auth/auth-part-guard.service';
import { ApiService } from './data/services/api/api.service';
import { CurrentUserModel } from './data/models/user/current-user.model';
import { devHttpInterceptorProvider } from './data/services/api/dev/DevHttpInterceptorProvider';
import { NavigationService } from './routing/navigation.service';
import { ReferralService } from './data/services/referal/referral.service';
import { HeaderComponent } from './ui/common/header/header.component';
import { LanguageSwitcherComponent } from './ui/common/language-switcher/language-switcher.component';
import { NotFoundRouteGuardService } from './routing/not-found-route-guard.service';
import { NotFoundRouteComponent } from './ui/pages/404/not-found-route/not-found-route.component';
import { environment } from '../environments/environment';
import { NotificationsModule } from './ui/common/notifications/notifications.module';
import { AuthMenuComponent } from './ui/common/header/menus/auth-menu/auth-menu.component';
import { CurrentUserApiModelTransformer } from './data/models/user/current-user.api-model-transformer';
import { UserProfileModel } from './data/models/user/user-profile.model';
import { UserProfileService } from './data/services/user/profile/user-profile.service';
import { AnalyticsClientProvider } from './data/services/analytics/AnalyticsClientProvider';
import { JugPortalsModule, JugUICommonModule, WebpackTranslateLoader } from '@ui/jug-ui';
import { ConfigurationService } from './data/services/configuration/configuration.service';
import { FooterComponent } from './ui/common/footer/footer.component';
import { MainMenuComponent } from './ui/common/header/menus/main-menu/main-menu.component';
import { MobileMainMenuPanelComponent } from './ui/common/header/menus/mobile-main-menu-panel/mobile-main-menu-panel.component';
import { MainMenuViewModel } from './ui/models/MainMenu.view-model';
import { MobileAuthMenuPanelComponent } from './ui/common/header/menus/mobile-auth-menu-panel/mobile-auth-menu-panel.component';
import { AuthMenuViewModel } from './ui/models/AuthMenu.view-model';
import { GdprService } from './data/services/gdpr/gdpr.service';
import { projectAPIErrorsHandlerProvider } from './data/services/handlers/ProjectAPIErrorsInterceptor';
import { currentEnvironmentConfigurationServiceProvider } from './data/services/configuration/current-environment-configuration.service';
import { PermissionService } from './data/services/permission/permission.service';
import { AvailableForRolesDirective } from './ui/components/permissions/available-for-roles.directive';
import { ClientSideLoggingProvider } from './data/services/logging/ClientSideLoggingProvider';
import { OAuthPartGuard } from './ui/pages/oauth/oauth-part-guard.services';
import { OAuthViewModel } from './ui/models/OAuthViewModel';
import { AuthorizationMetaProcessingService } from './data/services/auth/authorization-meta-processing.service';
import { UserProfileRoleRepresentationComponent } from './ui/components/user-profile-role-representation/user-profile-role-representation.component';


@NgModule({
  declarations: [
    AppComponent,
    NotFoundRouteComponent,

    HeaderComponent,
    LanguageSwitcherComponent,
    AuthMenuComponent,
    FooterComponent,
    MainMenuComponent,
    MobileMainMenuPanelComponent,
    MobileAuthMenuPanelComponent,
    AvailableForRolesDirective,
    UserProfileRoleRepresentationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      }
    }),
    SharedModule,
    AppRoutingModule,
    NotificationsModule,
    JugUICommonModule.forRoot(),
    JugPortalsModule.forRoot(),
  ],
  providers: [
    currentEnvironmentConfigurationServiceProvider,
    ClientSideLoggingProvider,
    AnalyticsClientProvider,
    NavigationService,
    projectAPIErrorsHandlerProvider,
    ApiService,
    CurrentUserApiModelTransformer,
    CurrentUserModel,
    UserProfileModel,
    UserProfileService,
    AuthService,
    AuthorizationMetaProcessingService,
    ReferralService,
    LoggedInGuard,
    AuthPartGuard,
    OAuthPartGuard,
    NotFoundRouteGuardService,
    ConfigurationService,
    GdprService,
    AuthMenuViewModel,
    OAuthViewModel,
    MainMenuViewModel,
    PermissionService,
    environment.api.useDevInterceptor ? devHttpInterceptorProvider : [],
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }
